
    #navbar.coloredStyle{
        transition: 0.5s ease;
        -webkit-transition: 0.5s ease;
        -ms-transition: 0.5s ease;
        background: white;
        box-shadow: $main-box-shadow;
    }
    #navbar.transparentStyle{
        transition: 0.5s ease;
        background: $hard-blue;
        -webkit-transition: 0.5s ease;
        -ms-transition: 0.5s ease;
        box-shadow: none;
    }
    #navbar{
        padding-top: 8px;
        padding-bottom: 8px;
        height: 65px;
        padding: 0 12px;
        background-color: $hard-blue;
        right: unset;
        color: white;
        @include msm{
            padding: 0 16px;
        }
        @include md{
            height: 75px;
            padding: 0 24px;
        }
        @include mmd{
            padding: 0 64px;
        }
        @include xxl{
            height: 80px;
            padding: 0 120px;
        }
        @include xxxl{
            padding: 0 200px;
        }
        .grow{
            display: flex;
            flex-grow: 1;
        }
        .toolbar{
            padding: 0;
            .menuItem{
                margin-right: 12px;
                color: white;
                font-weight: 400;
            }
            .menu-icon{
                display: block;
                margin-right: 12px;
                @include mmd{
                    display: none;
                }
            }
        }
        @at-root.logo{
            cursor: pointer;
            width: 100px;
            @include mmd{
                width: 120px;
                padding: 10px 0;
            }
            @include xxl{
                width: 150px;
            }
        }
    }