.zero-section{
    background-color: $light-blue;
    position: relative;
    @include section-container;
    padding-top: 24px;
    padding-bottom: 120px;
    @include md{
        padding-top: 48px;
        padding-bottom: 80px;
    }
    .title-container{
        display: flex;
        justify-content: center;
        .large{
            .box-text{
                font-size: 1.6rem;
            }
        }
    }
    
    .zero-description{
        color: white;
        font-size: 1.2rem;
        max-width: 800px;
        text-align: center;
        margin: auto;
        font-weight: 600;
        .highlight{
            color: $hard-blue;
        }
    }
    .zero-container{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        margin: auto;
        max-width: 800px;
        margin-top: 24px;
        padding: 12px 0;
        .zero-block{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            span{
                max-width: 200px;
                text-align: center;
                color: white;
                font-weight: 600;
            }
        }
        @include sm{
            flex-direction: row;
        }
        .color-box{
            margin-bottom: 12px;
        }
        .color-box.hard{
            .box-text{
            background-color: $hard-blue;
            color: white;
            }
        }
        .color-box.light{
            .box-text{
            background-color: white;
            color: $hard-blue;
            }
        }
        .box-text{
            max-width: 200px;
            text-align: center;
            padding: 8px;
            text-transform: uppercase;
            font-weight: 600;
            box-shadow: 7px 7px 8px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
            margin: 12px 0;
            font-size: 1.2rem;
        }

    }
    .leaves-container{
        position: absolute;
        bottom: 0;
        right: 0;
    }
}