.watertank {
  padding: 32px 0;
  background-color: $blue-black;
  .main-heading {
    color: white;
    text-align: center;
    margin-bottom: 12px;
    font-size: 1.8rem;
    font-weight: 600;
  }
  .main-description {
    color: white;
    text-align: center;
    max-width: 600px;
    margin: auto;
    font-size: 1rem;
  }
  .watertanks-container {
    @include section-container;
    margin: 24px 0;
    .watertank {
      margin-bottom: 24px;
      .image-container {
        box-shadow: $main-box-shadow;
        .watertank-image {
          width: 100%;
        }
      }
      .title-container {
        box-shadow: $main-box-shadow;
        padding: 8px 4px;
        margin: auto;
        margin-top: -25px;
        position: absolute;
        left: 0;
        right: 0;
        z-index: 2;
        max-width: 80%;
        @include md {
          max-width: 75%;
          padding: 12px 8px;
        }
        &.light-title {
          background-color: white;
          .title {
            color: $primary-blue;
          }
          .description {
            color: $primary-blue;
          }
        }
        &.dark-title {
          background-color: $primary-blue;
          .title {
            color: #ffffff;
          }
          .description {
            color: white;
          }
        }
        .title {
          text-align: center;
          font-weight: 600;
          font-size: 1rem;
          margin: 0;
          @include md {
            font-size: 1.4rem;
          }
        }
        .description{
            font-size: 0.9rem;
            font-weight: 500;
            margin: 0;
            text-align: center;
        }
      }
    }
  }
}
