#services {
    .banner {
      .top-banner {
        background-color: $primary-black;
        position: relative;
        min-height: 100vh;
        z-index: 0;
        @include msm {
          min-height: 100%;
        }
        @include xl {
          min-height: 50vh;
        }
        .banner-content {
          position: relative;
          z-index: -1;
          background: transparent;
          @include section-container;
          padding-top: 80px;
          padding-bottom: 20px;
        }
        .left-col {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
        }
        .main-heading {
          color: white;
          font-size: 18px;
          text-transform: uppercase;
          font-weight: 500;
          @include msm {
            font-size: 20px;
          }
          @include md {
            font-size: 24px;
          }
          @include mmd {
            font-size: 32px;
          }
          @include xl {
            font-size: 44px;
            line-height: 1.2;
            letter-spacing: 4px;
          }
        }
        .main-subheading {
          color: white;
          font-weight: 400;
          max-width: 400px;
          @include msm {
            font-size: 14px;
          }
          @include md {
            font-size: 16px;
          }
          @include lg {
            font-size: 18px;
          }
          @include xl {
            font-size: 22px;
          }
        }
        .cta-button {
          margin-top: 12px;
          @include square-button(black, white);
          height: 40px;
          .arrow {
            width: 10px;
            margin-left: 8px;
          }
        }
        .ant-col{
          min-height: 200px;
          margin-bottom: 16px;
          padding: 8px;
        }
          .service-image{
            background-size: cover;
            height: 100%;
            width: auto;          
            background-repeat: no-repeat;
            background-position: center;
          }
          .service-title{
            position: absolute;
            z-index: 2;
            bottom: 0;
            background: #1327B2;
            width: calc(100% - 16px);
            margin: auto;
            text-align: center;
            h3{
              text-align: center;
              text-transform: uppercase;
              color: white;
            }
          }
        .banner-asset {
          width: 100%;
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: -2;
        }
      }
    }
  }
  