#flexitank {
  .history {
      @include md{
          margin-top: -120px;
      }
    .back-container {
      .back-image {
        width: 100%;
      }
    }
    .left-col{
        padding-left: 12px;
        padding-right: 12px;
        margin-bottom: 24px;
        @include msm{
            padding-right: 16px;
            padding-left: 16px;
        }
        @include md{
            padding-right: 16px;
            padding-left: 0;
        }
    }
    .right-col{
        padding-right: 12px;
        padding-left: 12px;
        @include msm{
            padding-right: 16px;
            padding-left: 16px;
        }
        @include md{
            padding-right: 24px;
        }
        @include mmd{
            padding-right: 64px;
        }
    
        @include xxl{
            padding-right: 120px;
        }
        @include xxxl{
            padding-right: 200px;
        }
    }
    .side-container {
      .side-image {
        width: 100%;
      }
    }
    .side-content {
        @include md{
            margin-left: 80px;
            margin-top: 30px;
        }
      .history-heading {
          @extend .section-header;
      }
      .history-description {
          @extend .section-description;
          @include md{
              max-width: 350px;
          }
      }
    }
  }
}
