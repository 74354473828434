// Font-family
$main-font:'Montserrat', 'Muli', sans-serif;

// Font Weights
$light-font:200;
$regular-font:400;
$bold-font:600;
$bolder-font:700;

// Base Font
$base-font-weight: $regular-font;
$base-font-size:16px;
$base-line-height:1.4;
// Font Sizes
$xxxlarge-font:72px;
$xxlarge-font:54px;
$xlarge-font:40px;
$large-font: 25px;
$xtitle-font:32px;
$title-font:18px;
$default-font:16px;
$small-font: 14px;
$xsmall-font:12px;
$xxsmall-font: 10px;
$xxxsmall-font: 8px;



// Headings
$header-font-weight:$bold-font;
$subheader-font-weight:$regular-font;

// Border Radius
$card-border-radius:2px;
$tag-border-radius:8px;
$button-border-radius:4px;

// Box Shadows
$main-box-shadow: 7px 7px 8px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
$light-box-shadow:1px 1px 7px -2px #2196F3;
// Gutters
$card-gutter: 16px;

// Transition time
$transition-main:0.4s;