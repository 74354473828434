
.navmenu{
    display: none;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    margin: 0;
    @include mmd{
        display: flex;
    }
    .navItem{
        margin: 0 12px;
    }
    .link{
        text-decoration: none;
        color: white;
        margin: 0;
        font-weight: 500;
    }
    .black-link{
        text-decoration: none;
        color: black;
        margin: 0;
        font-weight: 500;
    }
    .blue-button{
        @include round-button(white, $primary-blue);
        span{
            font-weight: 500;
        margin-right: 8px;
            }
    }
    .white-button{
        @include round-button(black, white);
        span{
        font-weight: 500;
        margin-right: 8px;
        }
    }
}
