.contact-page{
    min-height: 100vh;
    .contact-row{
        min-height: 100vh;
    }
    .contact-form{
         background-color: $teal;
         height: 100%;
         padding-right: 12px;
         padding-left: 12px;
         .menu-icon{
            display: inline;
            margin-right: 12px;
            @include mmd{
                display: none;
            }         }
            .logo{
                padding: 10px 0;
            }
         @include msm{
             padding-right: 16px;
             padding-left: 16px;
         }
         @include mmd{
             padding-right: 32px;
             padding-left: 32px;
         }
     
         @include xxl{
             padding-right: 80px;
             padding-left: 80px; 
         }
         .form-container{
             margin-top: 24px;
             @include md{
                 margin-top: 32px;
             }
             @include lg{
                 margin-top: 64px;
             }
             .main-heading{
                font-size: 18px;
                font-weight: 500;
                margin-bottom: 4px;
                @include msm {
                  font-size: 20px;
                }
                @include md {
                  font-size: 24px;
                }
                @include mmd {
                  font-size: 32px;
                }
                @include xl {
                  font-size: 44px;
                  line-height: 1.2;
                  letter-spacing: 4px;
                }             }
         }
         .main-subheading{
            font-weight: 300;
            max-width: 600px;
            @include msm {
              font-size: 14px;
            }
            @include md {
              font-size: 16px;
            }
            @include lg {
              font-size: 18px;
            }
            @include xl {
              font-size: 20px;
            }
         }
         .ant-form{
             margin-top: 32px;
             .ant-input{
                 background-color: transparent;
                 border: none;
                 border-bottom: 1px solid;
                 border-radius: 0;
                 padding-bottom: 8px;
                 margin-bottom: 12px;
                 box-shadow: none;
                 &::placeholder{
                     color: black;
                     opacity: 1;
                     text-transform: uppercase;
                 }
             }
             .secondary-heading{
                 text-transform: uppercase;
                 font-size: 1.2rem;
                 letter-spacing: 1px;
                 margin-bottom: 24px;
             }
             .button-row{
                 align-items: center;
                 .contact-button{
                     @include round-button(white, $primary-orange);
                     img{
                         margin-left: 6px;
                     }
                 }
             }
             .button-container{
                 margin: 0;
             }
             .mail-us{
                 .mail-message{
                     text-transform: uppercase;
                     font-size: 0.8rem;
                     margin: 0;
                 }
                 a{
                     color: $primary-orange;
                     text-decoration: underline;
                 }
             }
         }
    }
    .contact-banner{
        display: none;
        @include lg{
            display: flex;
        }
        background-image: url("../../../images/contact-banner.png");
        min-height: 100vh;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        filter: drop-shadow(2px 4px 6px black);
        .contact-header{
            background-color: #1F1A17;
            height: 60px;
            width: 100%;
            @include mmd{
                padding-right: 32px;
            }
        
            @include xxl{
                padding-right: 80px;
            }
            .navmenu{
                height: 100%;
                max-width: 400px;
                margin-left: auto;
            }
        }
        .contact-footer{
            background-color: #1F1A17;
            width: 200px;
            height: 60px;
            position: fixed;
            bottom: 0;
            display: none;
        }
    }
}