@import '~antd/dist/antd.css';
@import './utilities/variables';
@import './utilities/colors';
@import './utilities/breakpoints';
@import './utilities/mixins';

@import './base';

@import './pages/home';
@import './pages/Flexitank/banner';
@import './pages/Flexitank/introduction';
@import './pages/Flexitank/history';
@import './pages/Flexitank/types';
@import './pages/Services/banner';
@import './pages/Services/service';
@import './pages/Products/banner';
@import './pages/Products/product';
@import './pages/Products/zero';
@import './pages/Products/watertank';
@import './pages/Contact/contact';


@import './components/product-image';





@import './layout/navbar';
@import './layout/navmenu';
