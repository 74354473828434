#flexitank {
  .desktop-flexitank, .mobile-flexitank{
    width: 100%;
  }
  .desktop-flexitank{
    display: none;
    @include md{
      display: block;
    }
  }
  .mobile-flexitank{
    display: block;
    @include md{
      display: none;
    }
  }
  .introduction {
    background-color: $blue-black;
    padding-top: 40px;
    padding-bottom: 40px;
    @include msm {
      padding-top: 80px;
    }
    // @include md{
    //     padding-bottom: 200px;
    // }
    @include section-container;
    .intro-header {
      @extend .section-header;
    }
    .intro-subheader {
      @extend .section-subheader;
    }
    .intro-description {
      @extend .section-description;
      max-width: 350px;
      b {
        font-weight: 600;
      }
    }
    .contact-button {
      @include square-button(white, $primary-blue);
      height: 40px;
      .arrow {
        width: 10px;
        margin-left: 8px;
      }
    }
    .card-col {
      margin-bottom: 24px;

      @include md {
        margin-bottom: 32px;
      }
    }
    .intro-card {
      box-shadow: $main-box-shadow;
      min-height: 150px;
      margin-bottom: 24px;
      @include lg{
        min-height: 200px;
        margin-bottom: 50px;
      }
      transition: $transition-main;
      &:hover {
        box-shadow: $light-box-shadow;
        transition: $transition-main;
      }
      .icon-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: -68px;
        .blue-circle {
          width: 80px;
          @include lg{
            width: 120px;
          }
        }
      }
      .intro-text {
        text-align: center;
        max-width: 300px;
        margin: auto;
        color: #707070;
        font-size: 18px;
        font-weight: 600;
        line-height: 1.4;
      }
    }
    .emblem-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      .emblem {
        width: 100%;
        max-width: 270px;
        margin: auto;
      }
    }
  }
}
