.service-box{
    padding-top: 24px;
    padding-bottom: 24px;
    padding-right: 12px;
    padding-left: 12px;
    height: 100%;
    position: relative;
    @include msm{
        padding-right: 16px;
        padding-left: 16px;
    }
    @include md{
        padding-right: 24px;
        padding-left: 24px;
    }
    .service-description{
        span{
            display: block;
            margin-bottom: 12px;
        }
    }
    .bold{
        font-weight: 600;
    }
    ul{
        list-style: none;
        li{
            margin-bottom: 8px;
            display: flex;
            img{
                width: 14px;
                margin-right: 12px;
            }
        }
    }
    .hash{
        position: absolute;
        top: -80px;
    }
}
.color-box{
    margin-bottom: 18px;
}
.service-box.hard{
    background-color: $hard-blue;
    color: white;
}
.service-box.light{
    background-color: white;
    color: black;
}
.color-box.hard{
    .box-text{
    background-color: $hard-blue;
    color: white;
    }
}
.color-box.light{
    .box-text{
    background-color: white;
    color: $hard-blue;
    }
}
.box-text{
    max-width: 200px;
    text-align: center;
    padding: 8px;
    text-transform: uppercase;
    font-weight: 600;
    box-shadow: 7px 7px 8px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
    margin: 12px 0;
    font-size: 1.2rem;
}
.left-col{
    @include mmd{
        padding-left: 64px;
    }

    @include xxl{
        padding-left: 120px; 
    }
    @include xxxl{
        padding-left: 200px;  
    }
}
.right-col{
    @include mmd{
        padding-right: 64px;
    }

    @include xxl{
        padding-right: 120px; 
    }
    @include xxxl{
        padding-right: 200px;  
    }
}