.product-section{
    @include section-container;
    padding-top: 24px;
    padding-bottom: 24px;
    @include md{
    padding-top: 48px;
    padding-bottom: 48px;

    }
    .row-reverse{
        flex-direction: column;
        @include md{
        flex-direction: row-reverse;
        }
    }
    .content-col{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .product-description{
        max-width: 450px;
    }
    .image-container{
        max-width: 550px;
        margin: auto;
        img{
            width: 100%;
            max-width: 420px;
        }
    }
    .title-container{
        max-width: 200px;
        text-align: center;
        padding: 8px;
        box-shadow: $main-box-shadow;
        margin: 12px 0;
        .product-title{
            margin: 0;
            text-transform: uppercase;
            font-weight: 600;
        }
    }
}
.white-section{
    .title-container{
        background-color: $hard-blue;
        .product-title{
            color: white;
        }
    }
}
.black-section{
    background-color: $primary-black;
    .title-container{
        background-color: white;
        .product-title{
            color:$hard-blue;
        }
    }
    .product-description{
        color: white;
    }
}